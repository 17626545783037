<template>
    <div class="service-center">
        <div class="service-top">
            <div class="logo" @click="goto('/')">
                <div class="picbox">
                    <div class="pic" style="background-image: url(/img/logo.png)"></div>
                </div>
            </div>
            <div class="titlebox">心理咨询服务中心</div>
        </div>
        <div class="contentbox">
            <div class="left-con">
                <div class="ltop">
                    <div class="titlebox">心理热线</div>
                    <div class="txt">{{base.tsXinli}}</div>
                    <div class="tel">
                        <div class="picbox">
                            <div class="pic" style="background-image: url(/img/phone.png)"></div>
                        </div>
                        <div class="t1">{{base.webTel}}（市级心理热线）</div>
                    </div>
                </div>
                <div class="lbottom">
                    <div class="titlebox">专科门诊时间</div>
                    <div class="txt" v-html="base.sjMenZhen"></div>
                    <!-- <div class="t1">
                            <div class="day">周一<span></span>~<span></span>周五：</div>
                             <div class="time">
                                上午8:00~11:30<br>下午13:30~17:00
                             </div>
                        </div>
                        <div class="t2"><div class="day">周<span></span>六：</div><div class="time">上午8:00~11:30</div></div>
                        <div class="t3">挂号上午11:15结束，下午16:45结束</div> -->
                </div>
            </div>
            <div class="right-con">
                <div class="item" v-for="(item, index) in doctor" :key="index">
                    <div class="titlebox">{{item.title}}</div>
                    <div class="doctor-card" @click="detailOn(item.peple.id, `${item.title}带头人介绍`)">
                        <div class="card-titlebox">
                            <div class="card-title">{{item.peple.callIndex}}</div>
                        </div>
                        <div class="card-introduce">
                            <div class="img">
                                <div class="picbox">
                                    <div class="pic" :style="{backgroundImage: 'url(' + baseUrl + item.peple.imgUrl +')'}"></div>
                                </div>
                            </div>
                            <div class="txt">
                                <div class="t1">{{item.peple.title}}</div>
                                <div class="t2">{{item.peple.fields.zhiwu}}</div>
                            </div>
                        </div>
                        <div class="card-detail">
                            <div class="detail1">职务：{{item.peple.fields.zhicheng}}</div>
                            <div class="detail2">资质：{{item.peple.fields.zhizhi}}</div>
                        </div>
                    </div>
                    <div class="team-card" @click="detailOn(item.team.id, `${item.title}团队介绍`)">
                        <div class="card-titlebox">
                            <div class="card-title">{{item.title}}团队</div>
                        </div>
                        <div class="img">
                            <div class="picbox">
                                <div class="pic" :style="{backgroundImage: 'url('+ baseUrl + item.team.imgUrl +')'}"></div>
                            </div>
                        </div>
                    </div>
                    <button class="btn" @click="detailOn(item.team.id, `${item.title}团队介绍`)">学科介绍</button>
                </div>
            </div>
            <!-- <div class="qrcode">
                <div style=" font-weight:bold; text-align:center; font-size: 20px;color: #ffffff">
                    扫码继续
                </div>
                <div class="qr-c">
                    <div ref="qrCode"></div>
                </div>
            </div> -->
            <div class="right-qrcode">
                <div class="picbox">
                    <div class="pic" style="background-image: url(/img/right_qrcode.png)"></div>
                </div>
                <img :src="baseUrl + base.wxLogo" alt="扫一扫">
            </div>
        </div>
        <div class="popup" :class="{active: detailShow}">
            <div class="shadow" @click="detailOff"></div>
            <div class="detail-popup">
                <div class="titlebox">
                    <div class="atitle">重点专科：{{popupTitle}}</div>
                    <div class="close" @click="detailOff">
                        <div class="picbox">
                            <div class="pic" style="background-image: url(/img/close.png)"></div>
                        </div>
                    </div>
                </div>
                <div class="conbox" v-html="string" style="white-space:pre-wrap;"></div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { getBase } from "@/js/request";
import { getInfo } from "@/js/request";
import { getServiceCenter } from "@/js/request";
import { getCategory } from "@/js/request";
// import QRCode from 'qrcode';
import { isPhone } from "../utils/tool"

export default {
    name: 'ServiceCenter',
    components: {

    },
    data() {
        return {
            base: {},
            baseUrl: '',
            category: [],
            doctor: [],
            detailShow: false,
            string: '',
            popupTitle: '',
            laze: false,
        }
    },
    methods: {
        goto(adress, cate) {
            this.$router.push({path: adress, query: cate});
        },
        detailOff() {
            this.detailShow = false;
        },
        detailOn(id, title) {
            this.detailShow = true;
            this.popupTitle = title;
            getInfo(id).then(res => {
                this.string = res.data.content;
            });
        }
    },
    mounted() {
        // this.$refs.qrCode.innerHTML = '';
        // QRCode.toCanvas(window.location.href, { errorCorrectionLevel: 'H', width: 100 }, (err, canvas) => {
        //     if (err) throw error
        //     this.$refs.qrCode.appendChild(canvas)
        // });
        if(isPhone()){
            this.$root.hideRight();
        }else{
            this.$root.showRight();
        }
        getBase().then(res => {
            this.base = res.data;
            this.baseUrl = res.config.baseURL;
        });
        getCategory(10).then(res => {
            this.category = res.data;
        });
        getServiceCenter().then(res => {
            for(let i = 0; i < this.category.length; i++) {
                for(let j = 0; j < res.data.length; j++) {
                    if(this.category[i].title === res.data[j].categoryTitle) {
                        if(res.data[j].callIndex === '团队介绍') {
                            this.category[i].team = res.data[j];
                        } else this.category[i].peple = res.data[j];
                    }
                }
            }
            this.doctor = this.category;
        });
    }
}
</script>

<style lang="scss">
@media (max-height: 1000px) {
    .service-center{
        padding: 20px 0 60px 0!important;
    }
    .service-top{
        margin-bottom: 20px!important;
    }
    .service-center .contentbox{
        height: 780px!important;
    }
    .team-card{
        margin-bottom: 20px!important;
    }
    .service-center .contentbox .left-con .lbottom{
        margin-top: 30px!important;
    }
}
.service-center {
    padding: 60px 0;
    box-sizing: border-box;
    max-height: 100vh;
    width: 1400px;
    margin: 0 auto;
    .service-top {
        display: flex;
        align-items: center;
        margin-bottom: 60px;
        .logo {
            @include sys-picbox(438px, 80px);
            cursor: pointer;
        }
        .titlebox {
            @include sys-font(42px, #000, bold);
            margin-left: 30px;
            padding-left: 30px;
            position: relative;
            &::before {
                content: '';
                width: 2px;
                height: 46px;
                background: #000;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
    }
    .contentbox {
        display: flex;
        width: 100%;
        height: 820px;
        position: relative;
        .left-con {
            @include sys-flex(space-between);
            width: 400px;
            height: 100%;
            margin-right: 40px;
            flex: none;
            .ltop, .lbottom {
                padding: 15px 60px;
                box-sizing: border-box;
                box-shadow: 0px 0px 50px 0px rgba(63,101,224,0.05);
                .titlebox {
                    @include sys-text-hide(1);
                    line-height: 66px;
                    margin-bottom: 24px;
                }
            }
            .ltop {
                @include sys-boxsize(100%, 300px, 20px);
                background: $acolor url(../assets/img/mentality_telbg.png) no-repeat 220px bottom/140px 140px;
                .titlebox {
                    @include sys-font(28px, #fff, bold);
                    border-bottom: 2px solid rgba(255, 255, 255, .2);
                }
                .txt {
                    @include sys-text-hide(5);
                    @include sys-font(16px, #fff);
                    line-height: 26px;
                    margin-bottom: 24px;
                }
                .tel {
                    display: flex;
                    align-items: center;
                    letter-spacing: 0;
                    @include sys-font(16px, #fff);
                    @include sys-picbox(18px, 20px);
                    .picbox {
                        margin-right: 12px;
                        flex: none;
                    }
                    .t1 {
                        @include sys-text-hide(1);
                    }
                }
            }
            .lbottom {
                @include sys-boxsize(100%, 480px, 20px);
                background: #fff url(../assets/img/mentality_timebg.png) no-repeat 152px 270px/228px 170px;
                overflow: hidden;
                .titlebox {
                    @include sys-font(28px, #000, bold);
                    border-bottom: 2px solid #EEEEEE;
                }
                .txt {
                    @include sys-font(16px, #333);
                    line-height: 26px;
                    letter-spacing: 1px;
                    white-space: pre-wrap;
                    &>div {
                        display: flex;
                        margin-bottom: 10px;
                    }
                    .day {
                        width: 100px;
                        flex: none;
                        display: flex;
                        span {
                            flex: 1;
                        }
                    }
                    .time {
                        flex: 1;
                    }
                }
            }
        }
        .right-con {
            display: flex;
            flex: 1;
            height: 100%;
            padding: 40px 30px;
            box-sizing: border-box;
            background: #FFFFFF;
            box-shadow: 0px 0px 50px 0px rgba(63,101,224,0.05);
            border-radius: 20px;
            .item {
                flex: 1;
                padding: 0 30px;
                @include sys-flex();
                .titlebox {
                    @include sys-text-hide(1);
                    @include sys-font(32px, #000, bold);
                    line-height: 34px;
                    text-align: center;
                    margin-bottom: 40px;
                    flex: none;
                }
                .doctor-card, .team-card {
                    width: 100%;
                    border-radius: 20px;
                    box-sizing: border-box;
                    border: 1px dashed #B6C7FF;
                    position: relative;
                    margin-bottom: 50px;
                    cursor: pointer;
                    .card-titlebox {
                        @include sys-flex();
                        width: calc(100% - 40px);
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        .card-title {
                            @include sys-text-hide(1);
                            @include sys-font(20px, #000, bold);
                            letter-spacing: 1px;
                            
                            padding: 0 10px;
                            background: #fff;
                        }
                    }
                }
                .doctor-card {
                    padding: 30px 20px;
                    .card-introduce {
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;
                        padding: 0 16px;
                        .img {
                            @include sys-radius-btn(100px, 100px);
                            @include sys-picbox(100%, 100%, cover);
                            overflow: hidden;
                        }
                        .txt {
                            margin-left: 20px;
                            .t1 {
                                @include sys-text-hide(1);
                                @include sys-font(24px, #000, bold);
                            }
                            .t2 {
                                @include sys-text-hide(1);
                                @include sys-font(20px, #000);
                            }
                        }
                    }
                    .card-detail {
                        @include sys-font(16px, #000);
                        text-align: center;
                        .detail2{
                            @include sys-text-hide(1);
                        }
                    }
                }
                .team-card {
                    padding: 30px;
                    @include sys-picbox(100%, 220px, cover);
                }
                .btn {
                    @include sys-text-hide(1);
                    @include sys-radius-btn(200px, 60px, $acolor);
                    @include sys-font(20px, #fff, bold);
                    cursor: pointer;
                    flex: none;
                }
            }
        }
    }
    .popup {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 999;
        opacity: 0;
        visibility: hidden;
        &.active {
            opacity: 1;
            visibility: visible;
        }
        .shadow {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .8);
        }
        .detail-popup {
            @include sys-flex();
            @include sys-boxsize(1200px, 800px, 20px, #fff);
            padding: 0 60px 60px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .titlebox {
                @include sys-flex(space-between, row);
                width: 100%;
                height: 120px;
                margin-bottom: 40px;
                flex: none;
                position: relative;
                &::after {
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: #EEEEEE;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
                .atitle {
                    @include sys-text-hide(1);
                    @include sys-font(36px, #000, bold);
                }
                .close {
                    @include sys-radius-btn(40px, 40px, #000);
                    @include sys-picbox(16px, 16px);
                    overflow: hidden;
                    transition: transform .3s;
                    &:hover {
                        transform: rotate(90deg);
                    }
                }
            }
            .conbox {
                flex: 1;
                width: 95%;
                @include sys-font(20px, #000);
                overflow-y: auto !important;
                &::-webkit-scrollbar {
                    width: 3px;
                }
                &::-webkit-scrollbar-thumb {
                    background: $acolor;
                    border-radius: 2px;
                }
                &::-webkit-scrollbar-resizer {
                    background: $acolor;
                }
                &::-webkit-scrollbar-track-piece {
                    background: #fff;
                    border-radius: 2px;
                }
                p {
                    text-indent: 40px;
                }
            }
        }
    }
    // .qr-c{
    //     text-align:center;

    //     div{
    //         text-align: center;
    //     }

    // }
    // .qrcode {
    //     position: fixed;
    //     width: 120px;
    //     height: 150px;
    //     bottom: 60px;
    //     right: 90px;
    //     background: #3F65E0;
    //     @include sys-picbox(130px, 191px);
    // }
}
</style>